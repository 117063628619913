import React, { useState, useEffect } from "react";
import { Card, Table, Button, Modal, Form, Input, message } from "antd";
import { listLeads} from "../../../graphql/queries"; 
import { createLead } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import SearchComponent from "../../../components/searchBar"; 

const client = generateClient();

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchLeads();
  }, []);

  async function fetchLeads() {
    try {
      const leadData = await client.graphql({ query: listLeads });
      const formattedLeads = leadData.data.listLeads.items;
      setLeads(formattedLeads);
      setFilteredLeads(formattedLeads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = leads.filter(lead =>
      lead.customerName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLeads(filtered);
  };

  const tableColumns = [
    {
      title: "Lead ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
    },
    {
      title: "Use Case",
      dataIndex: "useCase",
      key: "useCase",
    },
    {
      title: "Learn About",
      dataIndex: "learnAbout",
      key: "learnAbout",
    },
    // {
    //   title: "Franchise",
    //   dataIndex: "franchise",
    //   key: "franchise",
    // },
    // {
    //   title: "Created At",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    // },
  ];

  const handleAddLead = async (values) => {
    try {
      const newLead = {
        input: {
          customerName: values.customerName,
          location: values.location,
          from: values.from,
          contactPerson: values.contactPerson,
          contactNumber: values.contactNumber,
          vehicleType: values.vehicleType,
          useCase: values.useCase,
          learnAbout: values.learnAbout,
          franchise: values.franchise,
        },
      };
      await client.graphql({ query: createLead, variables: newLead });
      message.success("Lead added successfully!");
      fetchLeads(); 
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error adding lead:", error);
      message.error("Failed to add lead. Please try again.");
    }
  };

  const showAddLeadModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Leads</h2>
        <SearchComponent
          placeholder="Search by customer name"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={showAddLeadModal}>
          Add Lead
        </Button>
      </div>
      <Card title="Leads" style={{ margin: 20 }}>
        <Table dataSource={filteredLeads} columns={tableColumns} rowKey="id" />
      </Card>

      <Modal
        title="Add Lead"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleAddLead} layout="vertical">
          <Form.Item
            name="customerName"
            label="Customer Name"
            rules={[{ required: true, message: "Please enter customer name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: "Please enter location" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="from"
            label="From"
            rules={[{ required: true, message: "Please enter source" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactPerson"
            label="Contact Person"
            rules={[{ required: true, message: "Please enter contact person" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contactNumber"
            label="Contact Number"
            rules={[{ required: true, message: "Please enter contact number" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicleType"
            label="Vehicle Type"
            rules={[{ required: true, message: "Please enter vehicle type" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="useCase"
            label="Use Case"
            rules={[{ required: true, message: "Please enter use case" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="learnAbout"
            label="How did you learn about eWAKA"
            rules={[{ required: true, message: "Please enter source of lead" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="franchise"
            label="Franchise"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Lead
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Leads;
