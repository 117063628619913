/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBatteryByNumber = /* GraphQL */ `
  query GetBatteryByNumber($number: String!) {
    getBatteryByNumber(number: $number) {
      id
      number
      bike
      status
      franchise
      batteryOwnership
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const getSwapsByBatteryNumber = /* GraphQL */ `
  query GetSwapsByBatteryNumber($batteryNumber: String!) {
    getSwapsByBatteryNumber(batteryNumber: $batteryNumber) {
      id
      batteryInNumber
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      numberOfOrders
      operatorName
      platform
      franchise
      amount
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const getCustomerByPhoneNumber = /* GraphQL */ `
  query GetCustomerByPhoneNumber($phone_number: String!) {
    getCustomerByPhoneNumber(phone_number: $phone_number) {
      id
      name
      email
      phone_number
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: ID!) {
    getLead(id: $id) {
      id
      customerName
      location
      from
      contactPerson
      contactNumber
      vehicleType
      useCase
      learnAbout
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLeads = /* GraphQL */ `
  query ListLeads(
    $filter: ModelLeadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerName
        location
        from
        contactPerson
        contactNumber
        vehicleType
        useCase
        learnAbout
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMaintenance = /* GraphQL */ `
  query GetMaintenance($id: ID!) {
    getMaintenance(id: $id) {
      id
      equipmentName
      maintenanceDate
      technicianName
      issueDescription
      resolutionDescription
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMaintenances = /* GraphQL */ `
  query ListMaintenances(
    $filter: ModelMaintenanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMaintenances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        equipmentName
        maintenanceDate
        technicianName
        issueDescription
        resolutionDescription
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBike = /* GraphQL */ `
  query GetBike($id: ID!) {
    getBike(id: $id) {
      id
      chassisNumber
      bikeType
      fleet
      location
      ownership
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBikes = /* GraphQL */ `
  query ListBikes(
    $filter: ModelBikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chassisNumber
        bikeType
        fleet
        location
        ownership
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      clientName
      fleetSize
      location
      contactPerson
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientName
        fleetSize
        location
        contactPerson
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInventory = /* GraphQL */ `
  query GetInventory($id: ID!) {
    getInventory(id: $id) {
      id
      itemName
      category
      quantity
      status
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listInventories = /* GraphQL */ `
  query ListInventories(
    $filter: ModelInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInventories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemName
        category
        quantity
        status
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOperator = /* GraphQL */ `
  query GetOperator($id: ID!) {
    getOperator(id: $id) {
      id
      employeeIDNumber
      name
      phone_number
      email
      franchise
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listOperators = /* GraphQL */ `
  query ListOperators(
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeIDNumber
        name
        phone_number
        email
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBattery = /* GraphQL */ `
  query GetBattery($id: ID!) {
    getBattery(id: $id) {
      id
      number
      bike
      status
      franchise
      batteryOwnership
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listBatteries = /* GraphQL */ `
  query ListBatteries(
    $filter: ModelBatteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatteries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        number
        bike
        status
        franchise
        batteryOwnership
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFranchise = /* GraphQL */ `
  query GetFranchise($id: ID!) {
    getFranchise(id: $id) {
      id
      location
      address
      operator
      contactNumber
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFranchises = /* GraphQL */ `
  query ListFranchises(
    $filter: ModelFranchiseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFranchises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        location
        address
        operator
        contactNumber
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDispatch = /* GraphQL */ `
  query GetDispatch($id: ID!) {
    getDispatch(id: $id) {
      id
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      operatorName
      platform
      franchise
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDispatches = /* GraphQL */ `
  query ListDispatches(
    $filter: ModelDispatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDispatches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batteryOutNumber
        bike
        riderName
        riderPhoneNumber
        operatorName
        platform
        franchise
        status
        reference
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSwap = /* GraphQL */ `
  query GetSwap($id: ID!) {
    getSwap(id: $id) {
      id
      batteryInNumber
      batteryOutNumber
      bike
      riderName
      riderPhoneNumber
      numberOfOrders
      operatorName
      platform
      franchise
      amount
      status
      reference
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSwaps = /* GraphQL */ `
  query ListSwaps(
    $filter: ModelSwapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        batteryInNumber
        batteryOutNumber
        bike
        riderName
        riderPhoneNumber
        numberOfOrders
        operatorName
        platform
        franchise
        amount
        status
        reference
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDelivery = /* GraphQL */ `
  query GetDelivery($id: ID!) {
    getDelivery(id: $id) {
      id
      userID
      deliveryTime
      scheduledTime
      recipientName
      recipientPhoneNumber
      instructionsToRider
      vehicleType
      deliveryFee
      estimatedDeliveryTime
      paymentMethod
      origin
      destination
      originLatitude
      originLongitude
      destinationLatitude
      destinationLongitude
      deliveryRiderId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      pin
      status
      reason
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listDeliveries = /* GraphQL */ `
  query ListDeliveries(
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliveries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        deliveryTime
        scheduledTime
        recipientName
        recipientPhoneNumber
        instructionsToRider
        vehicleType
        deliveryFee
        estimatedDeliveryTime
        paymentMethod
        origin
        destination
        originLatitude
        originLongitude
        destinationLatitude
        destinationLongitude
        deliveryRiderId
        pin
        status
        reason
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      userID
      latitude
      longitude
      address
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        latitude
        longitude
        address
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      name
      phone_number
      amount
      paymentMethod
      platform
      status
      details
      reference
      requestId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone_number
        amount
        paymentMethod
        platform
        status
        details
        reference
        requestId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      url
      sent
      name
      type
      createdAt
      updatedAt
      riders {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        sent
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const operatorsByEmployeeIDNumber = /* GraphQL */ `
  query OperatorsByEmployeeIDNumber(
    $employeeIDNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    operatorsByEmployeeIDNumber(
      employeeIDNumber: $employeeIDNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeIDNumber
        name
        phone_number
        email
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const batteriesByNumber = /* GraphQL */ `
  query BatteriesByNumber(
    $number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBatteryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    batteriesByNumber(
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        number
        bike
        status
        franchise
        batteryOwnership
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dispatchesByBatteryOutNumber = /* GraphQL */ `
  query DispatchesByBatteryOutNumber(
    $batteryOutNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDispatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dispatchesByBatteryOutNumber(
      batteryOutNumber: $batteryOutNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batteryOutNumber
        bike
        riderName
        riderPhoneNumber
        operatorName
        platform
        franchise
        status
        reference
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const swapsByBatteryInNumber = /* GraphQL */ `
  query SwapsByBatteryInNumber(
    $batteryInNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    swapsByBatteryInNumber(
      batteryInNumber: $batteryInNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batteryInNumber
        batteryOutNumber
        bike
        riderName
        riderPhoneNumber
        numberOfOrders
        operatorName
        platform
        franchise
        amount
        status
        reference
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const swapsByBatteryOutNumber = /* GraphQL */ `
  query SwapsByBatteryOutNumber(
    $batteryOutNumber: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSwapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    swapsByBatteryOutNumber(
      batteryOutNumber: $batteryOutNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        batteryInNumber
        batteryOutNumber
        bike
        riderName
        riderPhoneNumber
        numberOfOrders
        operatorName
        platform
        franchise
        amount
        status
        reference
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deliveriesByDeliveryRiderId = /* GraphQL */ `
  query DeliveriesByDeliveryRiderId(
    $deliveryRiderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveriesByDeliveryRiderId(
      deliveryRiderId: $deliveryRiderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        deliveryTime
        scheduledTime
        recipientName
        recipientPhoneNumber
        instructionsToRider
        vehicleType
        deliveryFee
        estimatedDeliveryTime
        paymentMethod
        origin
        destination
        originLatitude
        originLongitude
        destinationLatitude
        destinationLongitude
        deliveryRiderId
        pin
        status
        reason
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRider = /* GraphQL */ `
  query GetRider($id: ID!) {
    getRider(id: $id) {
      id
      name
      sub
      lat
      lng
      email
      status
      phone_number
      vehicleType
      nationalIDNumber
      fleet
      franchise
      documents {
        nextToken
        __typename
      }
      guarantors {
        nextToken
        __typename
      }
      deliveries {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRiders = /* GraphQL */ `
  query ListRiders(
    $filter: ModelRiderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      name
      email
      phone_number
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone_number
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customersByPhone_number = /* GraphQL */ `
  query CustomersByPhone_number(
    $phone_number: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByPhone_number(
      phone_number: $phone_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        email
        phone_number
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchableCustomerFilterInput
    $sort: [SearchableCustomerSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCustomerAggregationInput]
  ) {
    searchCustomers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        email
        phone_number
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getSalesCustomers = /* GraphQL */ `
  query GetSalesCustomers($id: ID!) {
    getSalesCustomers(id: $id) {
      id
      year
      region
      county
      town
      customerName
      customerType
      salesType
      customerIndustry
      gender
      signupDate
      location
      contactPerson
      contactNumber
      noOfBikes
      bike
      status
      Comment
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSalesCustomers = /* GraphQL */ `
  query ListSalesCustomers(
    $filter: ModelSalesCustomersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        year
        region
        county
        town
        customerName
        customerType
        salesType
        customerIndustry
        gender
        signupDate
        location
        contactPerson
        contactNumber
        noOfBikes
        bike
        status
        Comment
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGuarantor = /* GraphQL */ `
  query GetGuarantor($id: ID!) {
    getGuarantor(id: $id) {
      id
      name
      phone_number
      nationalID
      createdAt
      updatedAt
      riderGuarantorsId
      owner
      __typename
    }
  }
`;
export const listGuarantors = /* GraphQL */ `
  query ListGuarantors(
    $filter: ModelGuarantorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuarantors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        phone_number
        nationalID
        createdAt
        updatedAt
        riderGuarantorsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const guarantorsByNationalID = /* GraphQL */ `
  query GuarantorsByNationalID(
    $nationalID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelGuarantorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    guarantorsByNationalID(
      nationalID: $nationalID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        phone_number
        nationalID
        createdAt
        updatedAt
        riderGuarantorsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      name
      description
      price
      unit
      imageUrl
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        price
        unit
        imageUrl
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRiderDocuments = /* GraphQL */ `
  query GetRiderDocuments($id: ID!) {
    getRiderDocuments(id: $id) {
      id
      riderId
      documentId
      rider {
        id
        name
        sub
        lat
        lng
        email
        status
        phone_number
        vehicleType
        nationalIDNumber
        fleet
        franchise
        createdAt
        updatedAt
        owner
        __typename
      }
      document {
        id
        url
        sent
        name
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listRiderDocuments = /* GraphQL */ `
  query ListRiderDocuments(
    $filter: ModelRiderDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRiderDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        riderId
        documentId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const riderDocumentsByRiderId = /* GraphQL */ `
  query RiderDocumentsByRiderId(
    $riderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRiderDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riderDocumentsByRiderId(
      riderId: $riderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        riderId
        documentId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const riderDocumentsByDocumentId = /* GraphQL */ `
  query RiderDocumentsByDocumentId(
    $documentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRiderDocumentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riderDocumentsByDocumentId(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        riderId
        documentId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
