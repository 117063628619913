import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, Modal, DatePicker, Space, message } from "antd";
import { listPayments } from "../../../graphql/queries";
import { createPayment, updatePayment } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { toast } from "react-toastify";
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import ExcelUploader from "../../../components/upload";
import * as XLSX from "xlsx";
import axios from 'axios';

const client = generateClient();
const { RangePicker } = DatePicker;

const BoltSend = () => {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const renderPaymentStatus = (requestStatus) => {
    if (requestStatus === "success") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "cancelled") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"blue"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "failed") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchPayments();
    checkUserRole();
  }, [client]);

  useEffect(() => {
    filterPayments();
  }, [payments, searchTerm, dateRange]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchPayments() {
    try {
      const paymentData = await client.graphql({ query: listPayments });
      const formattedPayments = paymentData.data.listPayments.items;
      const sortedPayments = formattedPayments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPayments(sortedPayments);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  }

  const handleRefresh = async () => {
    await fetchPayments();
    message.success("Payments refreshed successfully");
  }

  const sendStkPush = async (phoneNumber, amount, driverName, platform) => {
    try {
      // Generate a unique reference for each transaction
      // const reference = `BOLT_${Date.now()}_${driverName.replace(/\s+/g, '_')}`;
      const formatPhoneNumber = (phoneNumber) => {
        const phoneStr = String(phoneNumber);
        return phoneStr.replace(/\D/g, '').replace(/^(?:\+?254|0)(\d{9})$/, '254$1');
      };

      const stkPushData = {
        balanceId: 1102625,
        amount: amount,
        phoneNumber: formatPhoneNumber(phoneNumber),
        reference: `BOLT_${Date.now()}_${driverName.replace(/\s+/g, '_')}`,
        callbackUrl: 'https://peaceful-woodland-33125-d719be3aa54b.herokuapp.com/stk-push-callback',
      };

      const initialPaymentData = {
        name: driverName,
        phone_number: phoneNumber,
        amount: amount,
        platform: platform,
        status: 'pending',
        reference: stkPushData.reference,
      }

      const initialPaymentResponse = await client.graphql({
        query: createPayment,
        variables: { input: initialPaymentData }
      })
      console.log('Initial payment created:', initialPaymentResponse);

      const stkPushResponse = await axios.post('https://peaceful-woodland-33125-d719be3aa54b.herokuapp.com/stk-push', stkPushData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('STK Push response:', stkPushResponse.data);

      const { success, status, details, requestId, reference: responseReference, amount: responseAmount } = stkPushResponse.data;

      let finalStatus;
      if (success && status === 'pending') {
        finalStatus = 'pending';
      } else if (success && status === 'success') {
        finalStatus = 'success';
      } else if (!success && status === 'failed') {
        finalStatus = 'failed';
      } else {
        finalStatus = 'cancelled';
      }

      const updatedPaymentData = {
        reference: stkPushData.reference,
        status: finalStatus,
        details: details || 'No details provided',
        requestId,
      };

      const updatedPaymentResponse = await client.graphql({
        query: updatePayment,
        variables: { input: updatedPaymentData }
      })
      console.log('Updated payment:', updatedPaymentResponse);
      return stkPushResponse.data;
    } catch (error) {
      console.error('Error sending STK Push:', error);

      const failedPaymentData = {
        name: driverName,
        phone_number: phoneNumber,
        amount: amount,
        status: 'cancelled',
        details: error.message || 'Request error',
        reference: `BOLT_${Date.now()}_${driverName.replace(/\s+/g, '_')}`,
      };

      await client.graphql({
        query: createPayment,
        variables: { input: failedPaymentData },
      });

      throw error;
    }
  };

  const handleFileUpload = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet);

          if (json.length === 0) {
            toast.error("The Excel file is empty or could not be parsed.");
            return;
          }

          const requiredColumns = ['Driver', "Driver's Phone", 'Amount'];
          const headers = Object.keys(json[0]);
          const missingColumns = requiredColumns.filter(col => !headers.includes(col));

          if (missingColumns.length > 0) {
            toast.error(`Missing required columns: ${missingColumns.join(', ')}`);
            return;
          }

          setUploadedFile(json);
          message.success("File uploaded successfully");
        } catch (error) {
          console.error("Error processing file:", error);
          message.error("An error occurred while processing the file");
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error processing file:", error);
      message.error("An error occurred while processing the file");
    }
  };


  const handleSendPrompts = async () => {
    if (!uploadedFile) {
      message.error("Please upload a file first");
      return;
    }
    setIsProcessing(true);
    let processedCount = 0;
    let skippedCount = 0;
    let failedCount = 0;

    try {
      const paymentPromises = uploadedFile.map(async (row) => {

        try {
          const netEarnings = parseFloat(String(row['Amount']).replace(/[^\d.-]/g, ''));
          const amount = Math.round(netEarnings);

          if (!row["Driver's Phone"] || isNaN(amount) || amount <= 0) {
            skippedCount++;
            return null;
          }

          await sendStkPush(row["Driver's Phone"], amount, row['Driver']);
          processedCount++;
        } catch (error) {
          console.error(`Error processing payment for ${row['Driver']}:`, error);
          failedCount++;

        }
      });

      await Promise.all(paymentPromises);

      message.success(`
            Payments processed. 
            Successful: ${processedCount}, 
            Skipped: ${skippedCount}, 
            Failed: ${failedCount}
          `);

      setIsUploadModalVisible(false);
      setUploadedFile(null);
      fetchPayments();
    } catch (error) {
      console.error("Error processing file:", error);
      message.error("An error occurred while processing the file");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const filterPayments = () => {
    let filtered = payments;

    if (searchTerm) {
      filtered = filtered.filter(payment =>
        payment.amount.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        payment.riderName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(payment => {
        const paymentDate = new Date(payment.createdAt);
        return paymentDate >= dateRange[0].startOf('day') && paymentDate <= dateRange[1].endOf('day');
      });
    }

    filtered = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setFilteredPayments(filtered);
  };

  const tableColumns = [
    {
      title: "Payment Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Rider Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderPaymentStatus,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      defaultSortOrder: 'ascend',
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Payments</h2>
        <SearchComponent
          placeholder="Search by rider name, amount"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => setIsUploadModalVisible(true)}>
          Send Payment
        </Button>
      </div>
      <Card title={
    <span>
      Payments List
      <Tooltip title="Refresh">
            <ReloadOutlined
              onClick={handleRefresh}
              style={{ fontSize: '15px', cursor: 'pointer', marginLeft: '10px' }} />
          </Tooltip>
    </span>
  } extra={
        <Space>
         
          <RangePicker onChange={handleDateRangeChange} />

          {isAdmin && (
            <Tooltip title="Download as Excel">
              <DownloadOutlined
                onClick={() => downloadExcel(filteredPayments, "payments")}
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </Space>
      }
        style={{ margin: 20 }}
      >
        <Table dataSource={filteredPayments} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`/payment/${requestItem.id}`);
            },
          })} />
      </Card>
      <Modal
        title="Upload Payments"
        visible={isUploadModalVisible}
        onCancel={() => {
          setIsUploadModalVisible(false)
          setUploadedFile(null)
        }}
        footer={null}
        centered
      >
        <ExcelUploader
          onUpload={handleFileUpload}
          uploadProgress={uploadProgress}
        />
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          <Button type="primary"
            onClick={handleSendPrompts}
            loading={isProcessing}
            disabled={!uploadedFile || isProcessing}
          >
            Send Prompts
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default BoltSend;