import React, { useState, useEffect } from "react";
import { Card, Table, Button, Select } from "antd";
import { listInventories } from "../../../graphql/queries"; 
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../../../components/searchBar";

const client = generateClient();

const Inventory = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInventoryItems();
  }, [client]);

  const fetchInventoryItems = async () => {
    try {
      const inventoryResponse = await client.graphql({
        query: listInventories,
      });
      setInventoryItems(inventoryResponse.data.listInventories.items);
      setFilteredItems(inventoryResponse.data.listInventories.items);
    } catch (error) {
      console.error("Error fetching inventory items:", error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = inventoryItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filtered);
  };

  const handleCategoryFilter = (value) => {
    setCategoryFilter(value);
    if (value) {
      const filtered = inventoryItems.filter((item) => item.category === value);
      setFilteredItems(filtered);
    } else {
      setFilteredItems(inventoryItems);
    }
  };

  const tableColumns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Inventory</h2>
        <SearchComponent
          placeholder="Search by item name"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => navigate("/add-item")}>
          Add Item
        </Button>
      </div>

      <Card
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Inventory List</span>
            <Select
              placeholder="Filter by Category"
              style={{ width: 200 }}
              onChange={handleCategoryFilter}
            >
              <Select.Option value="Bikes">Bikes</Select.Option>
              <Select.Option value="Parts">Parts</Select.Option>
              <Select.Option value="Accessories">Accessories</Select.Option>
            </Select>
          </div>
        }
        style={{ margin: 20 }}
      >
        <Table
          dataSource={filteredItems}
          columns={tableColumns}
          rowKey="id"
          onRow={(item) => ({
            onClick: () => {
              navigate(`/inventory/${item.id}`);
            },
          })}
        />
      </Card>
    </div>
  );
};

export default Inventory;
