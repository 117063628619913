import React, { useState, useEffect, useCallback } from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { getCurrentUser, signOut } from "aws-amplify/auth";
// Auth
import SignUpForm from "./screens/auth/signup";
import SignInForm from "./screens/auth/signin";
import Profile from "./screens/auth/profile";
// BMS
import Dashboard from "./screens/dashboard";
import Dispatch from "./screens/battery/dispatch";
import AddDispatch from "./screens/battery/dispatch/addDispatch";
import DetailedDispatch from "./screens/battery/dispatch/detailedDispatch";
import Swap from "./screens/battery/swap";
import AddSwap from "./screens/battery/swap/addSwap";
import DetailedSwap from "./screens/battery/swap/detailedSwap";
import Batteries from "./screens/battery/batteries";
import BatteryForm from "./screens/battery/batteries/addBattery";
import DetailedBattery from "./screens/battery/batteries/detailedBattery";
import Franchises from "./screens/battery/franchises";
import FranchiseForm from "./screens/battery/franchises/addFranchise";
import DetailedFranchise from "./screens/battery/franchises/detailedFranchise";
import Operators from "./screens/battery/operators";
import OperatorForm from "./screens/battery/operators/addOperator";
import DetailedOperator from "./screens/battery/operators/detailedOperator";
import Track from "./screens/battery/track";
import Riders from "./screens/battery/riders";
import RiderForm from "./screens/battery/riders/addRider";
import DetailedRider from "./screens/battery/riders/detailedRider";
import { BatteryProvider } from "./contexts/batteryContext";
// Delivery
import Customers from "./screens/delivery/customers";
import DeliveryForm from "./screens/delivery/deliveries/addDelivery";
import DetailedRequest from "./screens/delivery/deliveries/detailedDelivery";
import Requests from "./screens/delivery/deliveries";
// Fleet
import Maintenance from "./screens/fleet/maintenance";
import Bikes from "./screens/fleet/bikes";
import FleetClients from "./screens/fleet/clients";
import Inventory from "./screens/fleet/inventory";
import Tracking from "./screens/fleet/track";
// Sales
import SalesCustomers from "./screens/sales/customers";
import Leads from "./screens/sales/leads";
//Finance
import BoltSend from "./screens/finance/payments";
import Invoices from "./screens/finance/invoices";
import DetailedPayment from "./screens/finance/payments/detailedPayments";

import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import SideBar from "./components/sideBar";
import { Image, Layout } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./assets/eWAKA logo.png";
import { UserOutlined } from "@ant-design/icons";


Amplify.configure(awsconfig);
const { Sider, Content, Footer, Header } = Layout;

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isSiderVisible, setIsSiderVisible] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSiderVisible(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleProfileIconClick = () => {
    if (!isAuthenticated) {
      toast('Kindly Sign In to View Profile')
    }
    else {
      navigate("/profile")
    }
  }

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser) {
          setIsAuthenticated(true);
          setUser(currentUser);
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (error) {
        // console.error("Error fetching current user: ", error);
        setIsAuthenticated(false);
        setUser(null);
      }
    };
    checkAuthStatus();
  }, []);

  const handleSuccessfulSignIn = useCallback(() => {
    setIsAuthenticated(true);
    toast.success("Successfully signed in!");
    navigate('/');
  }, [navigate]);

  const handleSignOut = async (onStart) => {
    try {
      onStart && onStart();
      await signOut({ global: true });
      setIsAuthenticated(false);
      setUser(null);
      toast.info("You have been signed out");
      navigate("/signin");
    } catch (error) {
      // console.error("Error signing out: ", error);
      toast.error("Error signing out. Please try again.");
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated) {
      return <Navigate to="/signin" replace />;
    }
    return children;
  };

  return (
    <BatteryProvider>
      <Layout style={{ minHeight: '100vh' }}>
        {!isAuthenticated ? null : (
          isSiderVisible && (
            <Sider
              style={{
                backgroundColor: "black",
                position: 'fixed',
                height: '100vh',
                left: 0,
                top: 0,
                bottom: 0,
                overflow: 'auto'
              }}
            >
              <Image src={logo} preview={false} />
              <hr />
              <SideBar style={{ backgroundColor: "black" }} />
            </Sider>
          ))}
        <Layout style={{ marginLeft: isAuthenticated && isSiderVisible ? 200 : 0 }}>
          <Header
            style={{
              backgroundColor: "black",
              color: "white",
              display: "flex",
              justifyContent: "flex-end",
              position: 'sticky',
              top: 0,
              zIndex: 1,
              width: '100%'
            }}
          >
            <UserOutlined
              style={{ fontSize: "25px", color: "#08c" }}
              onClick={handleProfileIconClick}
            />
          </Header>
          <Content >
            <Routes>
              <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/signin" replace />} />
              <Route path="/swap" element={isAuthenticated ? <Swap /> : <Navigate to="/signin" replace />} />
              <Route path='signin' element={isAuthenticated ? <Navigate to="/" replace /> : <SignInForm onSuccessfulSignIn={handleSuccessfulSignIn} />} />
              <Route path="add-dispatch" element={<ProtectedRoute><AddDispatch /></ProtectedRoute>} />
              <Route path="/dispatch" element={<ProtectedRoute><Dispatch /></ProtectedRoute>} />
              <Route path="/dispatch/:dispatchID" element={<ProtectedRoute><DetailedDispatch /></ProtectedRoute>} />
              <Route path="add-swap" element={<ProtectedRoute><AddSwap /></ProtectedRoute>} />
              <Route path="/swap/:swapID" element={<ProtectedRoute><DetailedSwap /></ProtectedRoute>} />
              <Route path="/batteries" element={<ProtectedRoute><Batteries /></ProtectedRoute>} />
              <Route path="profile" element={<ProtectedRoute><Profile handleSignOut={handleSignOut} /></ProtectedRoute>} />
              <Route path="add-battery" element={<ProtectedRoute><BatteryForm /></ProtectedRoute>} />
              <Route path="/batteries/battery/:batteryID" element={<ProtectedRoute><DetailedBattery /></ProtectedRoute>} />
              <Route path="/edit-battery/:batteryID" element={<ProtectedRoute><BatteryForm /></ProtectedRoute>} />
              <Route path="riders" element={<ProtectedRoute><Riders /></ProtectedRoute>} />
              <Route path="riders/rider/:riderID" element={<ProtectedRoute><DetailedRider /></ProtectedRoute>} />
              <Route path="add-rider" element={<ProtectedRoute><RiderForm /></ProtectedRoute>} />
              <Route path="/edit-rider/:riderID" element={<ProtectedRoute><RiderForm /></ProtectedRoute>} />
              <Route path="operators" element={<ProtectedRoute><Operators /></ProtectedRoute>} />
              <Route path="add-operator" element={<ProtectedRoute><OperatorForm /></ProtectedRoute>} />
              <Route path="/operators/operator/:operatorID" element={<ProtectedRoute><DetailedOperator /></ProtectedRoute>} />
              <Route path="/edit-operator/:operatorID" element={<ProtectedRoute><OperatorForm /></ProtectedRoute>} />
              <Route path="franchises" element={<ProtectedRoute><Franchises /></ProtectedRoute>} />
              <Route path="add-franchise" element={<ProtectedRoute><FranchiseForm /></ProtectedRoute>} />
              <Route path="/edit-franchise/:franchiseID" element={<ProtectedRoute><FranchiseForm /></ProtectedRoute>} />
              <Route path="/franchises/franchise/:franchiseID" element={<ProtectedRoute><DetailedFranchise /></ProtectedRoute>} />
              <Route path="/track" element={<ProtectedRoute><Track /></ProtectedRoute>} />
              <Route path="/customers" element={<ProtectedRoute><Customers /></ProtectedRoute>} />
              <Route path="/deliveries" element={<ProtectedRoute><Requests /></ProtectedRoute>} />
              <Route path="/deliveries/delivery/:deliveryID" element={<ProtectedRoute><DetailedRequest /></ProtectedRoute>} />
              <Route path="/add-delivery" element={<ProtectedRoute><DeliveryForm /></ProtectedRoute>} />
              <Route path="/signup" element={<SignUpForm />} />
              <Route path="/sales/customers" element={<ProtectedRoute><SalesCustomers /></ProtectedRoute>} />
              <Route path="/sales/leads" element={<ProtectedRoute><Leads /></ProtectedRoute>} />
              <Route path="/fleet/maintenance" element={<ProtectedRoute><Maintenance /></ProtectedRoute>} />
              <Route path="/fleet/bikes" element={<ProtectedRoute><Bikes /></ProtectedRoute>} />
              <Route path="/fleet/clients" element={<ProtectedRoute><FleetClients/></ProtectedRoute>} />
              <Route path="/fleet/inventory" element={<ProtectedRoute><Inventory /></ProtectedRoute>} />
              <Route path ="/fleet/tracking" element={<ProtectedRoute><Tracking /></ProtectedRoute>} />
              <Route path ="/finance/payments" element={<ProtectedRoute><BoltSend /></ProtectedRoute>} />
              <Route path ="/finance/invoices" element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
              <Route path ="/payment/:paymentID" element={<ProtectedRoute><DetailedPayment /></ProtectedRoute>} />
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
            <span>
              Copyright © 2024 eWAKA Mobility | Powered by eWAKA Mobility
            </span>
          </Footer>
        </Layout>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
      </Layout>
    </BatteryProvider>
  );
}

export default App;
