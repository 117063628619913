import React, { useState, useEffect } from "react";
import { Card, Table, Button, Tag, Modal, Form, Input, DatePicker, notification } from "antd";
import { listMaintenances } from "../../../graphql/queries";
import { createMaintenance } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import SearchComponent from "../../../components/searchBar";
import moment from "moment";

const client = generateClient();

const Maintenance = () => {
  const [maintenances, setMaintenances] = useState([]);
  const [filteredMaintenances, setFilteredMaintenances] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    fetchMaintenances();
  }, []);

  // Fetch the list of maintenance records
  async function fetchMaintenances() {
    try {
      const maintenanceData = await client.graphql({ query: listMaintenances });
      const formattedMaintenances = maintenanceData.data.listMaintenances.items;
      setMaintenances(formattedMaintenances);
      setFilteredMaintenances(formattedMaintenances);
    } catch (error) {
      console.error("Error fetching maintenances:", error);
    }
  }

  const handleSearch = (searchTerm) => {
    const filtered = maintenances.filter(maintenance =>
      maintenance.equipmentName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMaintenances(filtered);
  };

  const handleStatusTag = (status) => {
    switch (status) {
      case "Completed":
        return <Tag color="green">Completed</Tag>;
      case "In Progress":
        return <Tag color="blue">In Progress</Tag>;
      case "Pending":
        return <Tag color="orange">Pending</Tag>;
      default:
        return <Tag color="red">Unknown</Tag>;
    }
  };

  // Show modal to view maintenance details
  const showModal = (maintenance) => {
    setSelectedMaintenance(maintenance);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Show modal to schedule maintenance
  const showScheduleModal = () => {
    setIsScheduleModalVisible(true);
  };

  const handleScheduleOk = () => {
    form.submit();
  };

  const handleScheduleCancel = () => {
    setIsScheduleModalVisible(false);
  };

  // Schedule maintenance form submission
  const handleScheduleSubmit = async (values) => {
    try {
      const maintenanceInput = {
        equipmentName: values.equipmentName,
        maintenanceDate: values.maintenanceDate.toISOString(),
        technicianName: values.technicianName,
        issueDescription: values.issueDescription,
        status: "Pending", // Default status
      };

      await client.graphql({
        query: createMaintenance,
        variables: { input: maintenanceInput },
      });

      notification.success({
        message: "Success",
        description: "Maintenance scheduled successfully",
      });

      setIsScheduleModalVisible(false);
      form.resetFields();
      fetchMaintenances(); 
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to schedule maintenance",
      });
      console.error("Error scheduling maintenance:", error);
    }
  };

  const tableColumns = [
    {
      title: "Equipment Name",
      dataIndex: "equipmentName",
      key: "equipmentName",
    },
    {
      title: "Maintenance Date",
      dataIndex: "maintenanceDate",
      key: "maintenanceDate",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Technician Name",
      dataIndex: "technicianName",
      key: "technicianName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => handleStatusTag(status),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button type="primary" onClick={() => showModal(record)}>
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Maintenance Records</h2>
        <SearchComponent
          placeholder="Search by equipment name"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={showScheduleModal}>
          Schedule Maintenance
        </Button>
      </div>

      <Card title="Maintenance Records" style={{ margin: 20 }}>
        <Table
          dataSource={filteredMaintenances}
          columns={tableColumns}
          rowKey="id"
          pagination={{ pageSize: 5 }}
        />
      </Card>

      {/* Modal for viewing maintenance details */}
      <Modal
        title="Maintenance Details"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {selectedMaintenance && (
          <Form layout="vertical">
            <Form.Item label="Equipment Name">
              <Input
                value={selectedMaintenance.equipmentName}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Maintenance Date">
              <DatePicker
                value={moment(selectedMaintenance.maintenanceDate)}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Technician Name">
              <Input
                value={selectedMaintenance.technicianName}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Issue Description">
              <Input.TextArea
                value={selectedMaintenance.issueDescription}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Resolution Description">
              <Input.TextArea
                value={selectedMaintenance.resolutionDescription || "Not Resolved"}
                readOnly
              />
            </Form.Item>
            <Form.Item label="Status">
              {handleStatusTag(selectedMaintenance.status)}
            </Form.Item>
          </Form>
        )}
      </Modal>

      {/* Modal for scheduling maintenance */}
      <Modal
        title="Schedule Maintenance"
        visible={isScheduleModalVisible}
        onOk={handleScheduleOk}
        onCancel={handleScheduleCancel}
      >
        <Form form={form} layout="vertical" onFinish={handleScheduleSubmit}>
          <Form.Item
            label="Equipment Name"
            name="equipmentName"
            rules={[{ required: true, message: "Please input the equipment name!" }]}
          >
            <Input placeholder="Enter equipment name" />
          </Form.Item>
          <Form.Item
            label="Maintenance Date"
            name="maintenanceDate"
            rules={[{ required: true, message: "Please select the maintenance date!" }]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Technician Name"
            name="technicianName"
            rules={[{ required: true, message: "Please input the technician's name!" }]}
          >
            <Input placeholder="Enter technician's name" />
          </Form.Item>
          <Form.Item
            label="Issue Description"
            name="issueDescription"
            rules={[{ required: true, message: "Please describe the issue!" }]}
          >
            <Input.TextArea placeholder="Describe the issue" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Maintenance;
