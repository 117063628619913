import React, { useState, useEffect } from "react";
import { Card, Table, Button, Tooltip, Select } from "antd";
import { listClients } from "../../../graphql/queries"; 
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from "@ant-design/icons";
import SearchComponent from "../../../components/searchBar";

const client = generateClient();

const FleetClients = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClients();
  }, [client]);

  const fetchClients = async () => {
    try {
      const clientData = await client.graphql({
        query: listClients,
      });
      setClients(clientData.data.listClients.items);
      setFilteredClients(clientData.data.listClients.items);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSearch = (searchTerm) => {
    const filtered = clients.filter((client) =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    if (value) {
      const filtered = clients.filter((client) => client.status === value);
      setFilteredClients(filtered);
    } else {
      setFilteredClients(clients);
    }
  };

  const tableColumns = [
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Fleet Size",
      dataIndex: "fleetSize",
      key: "fleetSize",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Fleet Clients</h2>
        <SearchComponent
          placeholder="Search by client name"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => navigate("/add-client")}>
          Add Client
        </Button>
      </div>

      <Card
        title={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>Clients List</span>
            <Select
              placeholder="Filter by Status"
              style={{ width: 200 }}
              onChange={handleStatusChange}
            >
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </div>
        }
        extra={
          <Tooltip title="Download as Excel">
            <DownloadOutlined
              onClick={() => downloadExcel(filteredClients, "clients")}
              style={{ fontSize: "20px", cursor: "pointer" }}
            />
          </Tooltip>
        }
        style={{ margin: 20 }}
      >
        <Table
          dataSource={filteredClients}
          columns={tableColumns}
          rowKey="id"
          onRow={(client) => ({
            onClick: () => {
              navigate(`/client/${client.id}`);
            },
          })}
        />
      </Card>
    </div>
  );
};

export default FleetClients;
