import React, { useState, useEffect } from "react";
import { Card, Table, Tag, Button, Tooltip, DatePicker, Space } from "antd";
import { listSwaps } from "../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { useNavigate } from "react-router-dom";
import downloadExcel from "../../../components/download";
import { DownloadOutlined } from '@ant-design/icons';
import SearchComponent from "../../../components/searchBar";
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';

const client = generateClient();
const { RangePicker } = DatePicker;

const Swap = () => {
  const [swaps, setSwaps] = useState([]);
  const [filteredSwaps, setFilteredSwaps] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const renderSwapStatus = (requestStatus) => {
    if (requestStatus === "success") {
      return <Tag color={"green"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "pending") {
      return <Tag color={"orange"}>{requestStatus}</Tag>;
    }
    if (requestStatus === "failed") {
      return <Tag color={"red"}>{requestStatus}</Tag>;
    }
  };

  useEffect(() => {
    fetchSwaps();
    checkUserRole();
  }, [client]);

  useEffect(() => {
    filterSwaps();
  }, [swaps, searchTerm, dateRange]);

  async function checkUserRole() {
    try {
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      const isAdminUser = userAttributes['custom:isAdmin'] === 'true';

      const groups = session.tokens.accessToken.payload['cognito:groups'];

      const isInAdminGroup = groups && groups.includes('Admins');
      const isInOperatorsGroup = groups && groups.includes('Operators');

      setIsAdmin(isAdminUser || isInAdminGroup);

    } catch (error) {
      console.error("Error checking user role:", error);
    }
  }

  async function fetchSwaps() {
    try {
      const swapData = await client.graphql({ query: listSwaps });
      const formattedSwaps = swapData.data.listSwaps.items;
      const sortedSwaps = formattedSwaps.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setSwaps(sortedSwaps);
    } catch (error) {
      console.error("Error fetching swaps:", error);
    }
  }

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };
 
  const filterSwaps = () => {
    let filtered = swaps;

    if (searchTerm) {
      filtered = filtered.filter(swap =>
        swap.batteryInNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.batteryOutNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.franchise.toLowerCase().includes(searchTerm.toLowerCase()) ||
        swap.riderName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (dateRange && dateRange[0] && dateRange[1]) {
      filtered = filtered.filter(swap => {
        const swapDate = new Date(swap.createdAt);
        return swapDate >= dateRange[0].startOf('day') && swapDate <= dateRange[1].endOf('day');
      });
    }

    filtered = filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setFilteredSwaps(filtered);
  };

  const tableColumns = [
    {
      title: "Battery In",
      dataIndex: "batteryInNumber",
      key: "batteryInNumber",
    },
    {
      title: "Battery Out",
      dataIndex: "batteryOutNumber",
      key: "batteryOutNumber",
    },
    {
      title: "Rider",
      dataIndex: "riderName",
      key: "riderName",
    },
    {
      title: "Franchise",
      dataIndex: "franchise",
      key: "franchise",
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Reference",
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderSwapStatus,
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      defaultSortOrder: 'ascend',
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          padding: 20,
        }}
      >
        <h2>Swaps</h2>
        <SearchComponent
          placeholder="Search by battery number"
          onSearch={handleSearch}
        />
        <Button type="primary" onClick={() => navigate("/add-swap")}>
          Add Swap
        </Button>
      </div>
      <Card title="Swaps List" extra={
          <Space>
            <RangePicker onChange={handleDateRangeChange} />
            {isAdmin && (
              <Tooltip title="Download as Excel">
                <DownloadOutlined 
                  onClick={() => downloadExcel(filteredSwaps, "swaps")}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                />
              </Tooltip>
            )}
          </Space>
        } 
        style={{ margin: 20 }}
      >
        <Table dataSource={filteredSwaps} columns={tableColumns} rowKey="id"
          onRow={(requestItem) => ({
            onClick: () => {
              navigate(`/swap/${requestItem.id}`);
            },
          })} />
      </Card>
    </div>
  );
};

export default Swap;