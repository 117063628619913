import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, InputNumber } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { generateClient } from 'aws-amplify/api';
// import { post } from 'aws-amplify/api';
import axios from 'axios';
import { updateBattery, createSwap, updateSwap } from "../../../../graphql/mutations";
import { listOperators, listFranchises, listBatteries, listRiders, getSwap } from '../../../../graphql/queries'

const { Option } = Select;

const filterOption = (input, option) =>
  (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const AddSwap = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const swap = location.state?.swap;
  const client = generateClient();
  const [isLoading, setIsLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [batteries, setBatteries] = useState([]);
  const [allBatteries, setAllBatteries] = useState([]);
  const [availableBatteriesIn, setAvailableBatteriesIn] = useState([]);
  const [availableBatteriesOut, setAvailableBatteriesOut] = useState([]);
  const [franchises, setFranchises] = useState([]);
  const [riders, setRiders] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [selectedBikeType, setSelectedBikeType] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    if (amount !== null) {
      form.setFieldsValue({ amount: amount });
    }
  }, [amount, form]);

  // Fetch batteries for "batteryIn" selection
  useEffect(() => {
    const fetchAvailableBatteriesIn = async () => {
      if (selectedBikeType) {
        let allAvailableBatteries = [];
        let nextToken = null;
        do {
          const batteriesResponse = await client.graphql({
            query: listBatteries,
            variables: {
              filter: {
                and: [
                  { status: { eq: "in use" } },
                  { bike: { eq: selectedBikeType } }
                ]
              },
              limit: 1000,
              nextToken: nextToken
            }
          });
          const fetchedBatteries = batteriesResponse.data.listBatteries.items || [];
          allAvailableBatteries = [...allAvailableBatteries, ...fetchedBatteries];
          nextToken = batteriesResponse.data.listBatteries.nextToken;
        } while (nextToken);

        setAvailableBatteriesIn(allAvailableBatteries);
      }
    };

    fetchAvailableBatteriesIn();
  }, [selectedBikeType]);

  // Fetch batteries for "batteryOut" selection
  useEffect(() => {
    const fetchAvailableBatteriesOut = async () => {
      if (selectedBikeType) {
        let allAvailableBatteries = [];
        let nextToken = null;
        do {
          const batteriesResponse = await client.graphql({
            query: listBatteries,
            variables: {
              filter: {
                and: [
                  { status: { eq: "charged" } },
                  { bike: { eq: selectedBikeType } }
                ]
              },
              limit: 1000,
              nextToken: nextToken
            }
          });
          const fetchedBatteries = batteriesResponse.data.listBatteries.items || [];
          allAvailableBatteries = [...allAvailableBatteries, ...fetchedBatteries];
          nextToken = batteriesResponse.data.listBatteries.nextToken;
        } while (nextToken);

        setAvailableBatteriesOut(allAvailableBatteries);
      }
    };

    fetchAvailableBatteriesOut();
  }, [selectedBikeType]);

  const filteredBatteriesIn = allBatteries.filter(battery => battery.bike === selectedBikeType);

  //fetch franchises
  useEffect(() => {
    const fetchData = async () => {
      const franchisesResponse = await client.graphql({ query: listFranchises });
      setFranchises(franchisesResponse.data.listFranchises.items || []);
    };

    fetchData();
  }, []);

  //fetch operators in selected franchise
  useEffect(() => {
    const fetchOperators = async () => {
      if (selectedFranchise) {
        const operatorsResponse = await client.graphql({
          query: listOperators,
          variables: { filter: { franchise: { eq: selectedFranchise } } }
        });
        setOperators(operatorsResponse.data.listOperators.items || []);
      }
    };

    fetchOperators();
  }, [selectedFranchise]);


  //fetch riders in selected franchise and platform
  useEffect(() => {
    const fetchAllRiders = async () => {
      try {
        let allRiders = [];
        let nextToken = null;
        let filter = {};
        if (selectedPlatform) {
          filter.fleet = { eq: selectedPlatform };
        }
        if (selectedBikeType) {
          filter.vehicleType = { eq: selectedBikeType };
        }

        console.log("Fetching riders with filter:", JSON.stringify(filter, null, 2));

        do {
          const ridersResponse = await client.graphql({
            query: listRiders,
            variables: {
              filter: filter,
              limit: 1000,
              nextToken: nextToken
            }
          });

          const fetchedRiders = ridersResponse.data.listRiders.items || [];
          allRiders = [...allRiders, ...fetchedRiders];
          nextToken = ridersResponse.data.listRiders.nextToken;

          console.log(`Fetched ${fetchedRiders.length} riders. Total: ${allRiders.length}`);
        } while (nextToken);

        console.log("Total riders fetched:", allRiders.length);
        setRiders(allRiders);
      } catch (error) {
        console.error('Error fetching riders:', error);
        toast.error("Failed to fetch riders");
      }
    };

    fetchAllRiders();
  }, [selectedPlatform, selectedBikeType]);

  //check if swap exists for edit
  useEffect(() => {
    if (swap) {
      form.setFieldsValue({
        riderName: swap.riderName,
        batteryNumberIn: swap.batteryNumberIn,
        bike: swap.bike,
        batteryNumberOut: swap.batteryNumberOut,
        franchise: swap.franchise,
        platform: swap.platform,
        status: swap.status,
      });
    }
  }, [swap, form]);

  useEffect(() => {
    const updateAmount = () => {
      if (selectedBikeType === "Shujaa" || selectedBikeType === "Shujaa2") {
        setAmount(60);
      } else if (selectedBikeType === "Jasiri") {
        setAmount(170);
      } else {
        setAmount(null);
      }
    }
    updateAmount();
    form.setFieldsValue({ batteryIn: undefined });
  }, [selectedBikeType, form]);

  const handleRiderChange = async (riderId) => {
    const selectedRider = riders.find(rider => rider.id === riderId);
    if (selectedRider) {
      form.setFieldsValue({
        riderName: selectedRider.name,
        riderPhoneNumber: selectedRider.phone_number
      });
    }
  };

  // Number starts with 254
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '').replace(/^(?:\+?254|0)(\d{9})$/, '254$1');
  };

  

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);

      const batteryIn = availableBatteriesIn.find(b => b.number === values.batteryIn);
      const batteryOut = availableBatteriesOut.find(b => b.number === values.batteryOut);

      if (!batteryIn || !batteryOut) {
        throw new Error("Selected batteries not found");
      }

      const swapAmount = parseInt(values.amount);
      const reference = `swap-${Date.now()}`;

      const swapData = {
        batteryInNumber: values.batteryIn,
        batteryOutNumber: values.batteryOut,
        bike: values.bike,
        riderName: values.riderName,
        riderPhoneNumber: values.riderPhoneNumber,
        numberOfOrders: values.numberOfOrders,
        operatorName: values.operatorName,
        platform: values.platform,
        franchise: values.franchise,
        amount: swapAmount,
        status: "pending",
        reference: reference
      };

      // Create pending swap
      const pendingSwap = await client.graphql({
        query: createSwap,
        variables: { input: swapData }
      });

      const swapId = pendingSwap.data.createSwap.id;

      // Initiate STK push
      const stkPushData = {
        balanceId: 1102625,
        amount: swapAmount,
        phoneNumber: formatPhoneNumber(values.riderPhoneNumber),
        reference: swapData.reference,
      };

      try {
        const stkPushResponse = await axios.post('https://peaceful-woodland-33125-d719be3aa54b.herokuapp.com/stk-push'
          , stkPushData);
        console.log('Full STK Push response:', stkPushResponse);

        const { paymentStatus } = stkPushResponse.data.data;

        if (paymentStatus.success) {

          await Promise.all([
            // Update the incoming battery
            client.graphql({
              query: updateBattery,
              variables: {
                input: {
                  id: batteryIn.id,
                  franchise: swapData.franchise,
                  status: "charged"
                }
              }
            }),
            // Update the outgoing battery
            client.graphql({
              query: updateBattery,
              variables: {
                input: {
                  id: batteryOut.id,
                  status: "in use"
                }
              }
            }),
            // Update swap status
            client.graphql({
              query: updateSwap,
              variables: {
                input: {
                  id: swapId,
                  status: "success",
                  reference: stkPushResponse.data.data.requestId
                }
              }
            })
          ]);

          toast.success("Payment successful and swap completed!");
          navigate("/swap");
        } else {
          const status = paymentStatus.status === 'cancelled' ? "cancelled" : "failed";

          await client.graphql({
            query: updateSwap,
            variables: {
              input: {
                id: swapId,
                status: status,
                reference: reference
              }
            }
          });
          toast.error(paymentStatus.details || "Payment failed");
        }
      } catch (error) {
        console.error('Payment processing error:', error);

        // Update swap status to failed
        await client.graphql({
          query: updateSwap,
          variables: {
            input: {
              id: swapId,
              status: "failed",
              reference: reference
            }
          }
        });

        const errorMessage = error.response?.status === 400 ?
          "Payment request failed. Please try again." :
          "An error occurred while processing payment";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error(error.message || "An error occurred while processing the swap");
    } finally {
      setIsLoading(false);
    }
  };
  // const createSwapAndUpdateBatteries = async (swapData, batteryIn, batteryOut) => {

  //   // Update the incoming battery
  //   await client.graphql({
  //     query: updateBattery,
  //     variables: {
  //       input: {
  //         id: batteryIn.id,
  //         franchise: swapData.franchise,
  //         status: "charged"
  //       }
  //     }
  //   });

  //   // Update the outgoing battery
  //   await client.graphql({
  //     query: updateBattery,
  //     variables: {
  //       input: {
  //         id: batteryOut.id,
  //         status: "in use"
  //       }
  //     }
  //   });

  //   // Create the swap
  //   await client.graphql({
  //     query: createSwap,
  //     variables: { input: swapData }
  //   });
  // };

  const isEditMode = !!swap;
  const headingText = isEditMode ? "Edit Swap Details Below:" : "Enter Swap Details Below:";

  return (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 20, gap: 20, alignItems: "center", maxWidth: "600px", margin: "auto" }}>
      <h3 style={{ marginTop: 20 }}>{headingText}</h3>
      <Form
        form={form}
        name="riderForm"
        onFinish={handleSubmit}
        style={{ width: "70%" }}
      >

        {/* Franchise Location*/}
        <Form.Item
          name="franchise"
          rules={[{ required: true, message: "Please select the franchise location!" }]}
        >
          <Select
            placeholder="Franchise Location"
            onChange={(value) => setSelectedFranchise(value)}
            showSearch
            filterOption={filterOption}
          >
            {franchises.map((franchise) => (
              <Option key={franchise.id} value={franchise.location}>
                {franchise.location}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Bike Type */}
        <Form.Item
          name="bike"
          rules={[{ required: true, message: "Please input the battery's bike type!" }]}
        >
          <Select
            placeholder="Bike Type"
            onChange={(value) => {
              setSelectedBikeType(value);
              if (value === "Jasiri") {
                setAmount(170);
              } else if (value === "Shujaa" || value === "Shujaa2") {
                setAmount(60);
              } else {
                setAmount(null);
              }
            }}
            showSearch
            filterOption={filterOption}
          >
            <Option value="Shujaa">Shujaa</Option>
            <Option value="Shujaa2">Shujaa 2.0</Option>
            <Option value="Jasiri">Jasiri</Option>
          </Select>
        </Form.Item>

        {/* Battery In */}
        <Form.Item
          name="batteryIn"
          rules={[{ required: true, message: "Please select the battery in!" }]}
        >
          <Select
            placeholder="Battery In Number"
            showSearch
            filterOption={filterOption}
            disabled={!selectedBikeType}
          >
            {availableBatteriesIn.map((battery) => (
              <Option key={battery.id} value={battery.number}>
                {battery.number}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Battery Out */}
        <Form.Item
          name="batteryOut"
          rules={[{ required: true, message: "Please enter the number of the battery out!" }]}
        >
          <Select
            placeholder="Battery Out Number"
            disabled={!selectedBikeType}
            showSearch
            filterOption={filterOption}
          >
            {availableBatteriesOut.map((battery) => (
              <Option key={battery.id} value={battery.number}>
                {battery.number}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Operator Name */}
        <Form.Item
          name="operatorName"
          rules={[{ required: true, message: "Please enter the operator's name!" }]}
        >
          <Select placeholder="Operator Name"
            disabled={!selectedFranchise}
            showSearch
            filterOption={filterOption}
          >
            {operators.map((operator) => (
              <Option key={operator.id} value={operator.name}>
                {operator.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Platform */}
        <Form.Item
          name="platform"
          rules={[{ required: true, message: "Please input the rider platform!" }]}
        >
          <Select
            placeholder="Platform"
            onChange={(value) => setSelectedPlatform(value)}
            showSearch
            filterOption={filterOption}
          >
            <Option value="Bolt">BOLT</Option>
            <Option value="Kutuma">KUTUMA</Option>
          </Select>
        </Form.Item>

        {/* Number of Orders */}
        <Form.Item
          name="numberOfOrders"
          rules={[
            { required: true, message: "Please enter the number of orders!" },
            { type: 'number', min: 0, message: "Number of orders must be a positive number!" }
          ]}
        >
          <InputNumber placeholder="Number of Orders" style={{ width: '100%' }} />
        </Form.Item>

        {/* Rider Name */}
        <Form.Item
          name="riderName"
          rules={[{ required: true, message: "Please select a rider!" }]}
        >
          <Select
            placeholder="Select Rider"
            onChange={handleRiderChange}
            disabled={!selectedPlatform || !selectedFranchise || !selectedBikeType}
            showSearch
            filterOption={filterOption}
          >
            {riders.map((rider) => (
              <Option key={rider.id} value={rider.id}>
                {rider.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Rider Phone Number */}
        <Form.Item
          name="riderPhoneNumber"
          rules={[
            { required: true, message: "Please input the phone number!" },
          ]}
        >
          <Input
            placeholder="Rider Phone Number"
            disabled
          />
        </Form.Item>

        {/*Amount */}
        <Form.Item
          name="amount"
          rules={[{ required: true, message: "Please enter the amount to be paid!" }]}
        >
          <Input
            placeholder="Amount"
            disabled={true}
          />
        </Form.Item>

        {/* Reference */}
        {/* <Form.Item
          name="reference"
          rules={[{ required: true, message: "Please enter the payment reference code" }]}
        >
          <Input
            placeholder="Reference Code"
          />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Processing Payment" : (isEditMode ? "Update Swap" : "Confirm Swap")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSwap;